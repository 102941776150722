<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="webIcon" @click="toWeb"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>
    <div class="figure"></div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
      <div class="ucTip"></div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toWeb() {
      window.open("https://emcmqw.com");
    },
    toTg() {
      window.open("https://t.me/mbjmgfq");
    },
    toPotato() {
      window.open("https://psslk.org/mbjmgfq");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    height: 102px;
    width: 394px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 67px;
    left: 137px;
  }
  .contactBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 202px;
    top: 59px;
    .webIcon {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 934px;
    width: 752px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 100px;
    bottom: 0;
  }
  .logoText {
    height: 338px;
    width: 886px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 164px;
    top: 250px;
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 164px;
    bottom: 166px;
    .qrcodeBox {
      height: 248px;
      width: 248px;
      background-color: white;
      border-radius: 10px;
      margin-right: 50px;
    }
    .ucTip {
      height: 250px;
      width: 486px;
      background: url("./../../../assets/images/pc/ucTip.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
